import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAddLandSearch, fetchCancelLandSearch, fetchDeleteLandSearch, fetchDoneLandSearch, fetchGetLandSearch, fetchToWorkLandSearch, fetchUpdateLandSearch } from '../../../api/land-search-api';


export const fetchAddLandSearchAsync = createAsyncThunk(
    "landSearch/fetchAddLandSearchAsync",
    fetchAddLandSearch
);

export const fetchGetLandSearchAsync = createAsyncThunk(
    "landSearch/fetchGetLandSearchAsync",
    fetchGetLandSearch
);

export const fetchUpdateLandSearchAsync = createAsyncThunk(
    "landSearch/fetchUpdateLandSearchAsync",
    fetchUpdateLandSearch
);

export const fetchDeleteLandSearchAsync = createAsyncThunk(
    "landSearch/fetchDeleteLandSearchAsync",
    fetchDeleteLandSearch
);

export const fetchCancelLandSearchLandSearchAsync = createAsyncThunk(
    "landSearch/fetchCancelLandSearchLandSearchAsync",
    fetchCancelLandSearch
);

export const fetchToWorkLandSearchLandSearchAsync = createAsyncThunk(
    "landSearch/fetchToWorkLandSearchLandSearchAsync",
    fetchToWorkLandSearch
);

export const fetchDoneLandSearchLandSearchAsync = createAsyncThunk(
    "landSearch/fetchDoneLandSearchLandSearchAsync",
    fetchDoneLandSearch
);

const changeDataRequests = [
    fetchAddLandSearchAsync,
    fetchUpdateLandSearchAsync,
    fetchDeleteLandSearchAsync,
    fetchCancelLandSearchLandSearchAsync,
    fetchToWorkLandSearchLandSearchAsync,
    fetchDoneLandSearchLandSearchAsync
]

const landSearchInitialState = {
    cadastre: '',
    author: {},
    client: {},
    result: '',
    description: '',
}

const initialState = {
    entity: landSearchInitialState,
    landsearchTypes: [],
    status: 'idle'
}

export const LandSearchSlice = createSlice({
    name: 'landSearch',
    initialState,
    extraReducers: (builder) => {

        changeDataRequests.forEach(req => {
            builder
                .addCase(req.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(req.rejected, (state) => {
                    state.status = 'idle';
                })
                .addCase(req.fulfilled, (state, action) => {
                    state.status = 'idle';
                })
        })

        builder
            // get 
            .addCase(fetchGetLandSearchAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandSearchAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandSearchAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
            })

    },
    reducers: {
        clearLandSearch: (state => {
            state.entity = landSearchInitialState;
        }),
    }
})

export const { clearLandSearch } = LandSearchSlice.actions;
export const getLandSearch = (state) => state.landSearch.entity;
export default LandSearchSlice.reducer