import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAddHouse, fetchGetHouse, fetchUpdateHouse } from '../../../api/house-api';


export const fetchAddHouseAsync = createAsyncThunk(
    "house/fetchAddHouseAsync",
    fetchAddHouse
);

export const fetchUpdateHouseAsync = createAsyncThunk(
    "house/fetchUpdateHouseAsync",
    fetchUpdateHouse
);

export const fetchGetHouseAsync = createAsyncThunk(
    "house/fetchGetHouseAsync",
    fetchGetHouse
);


const initialState = {
    entity: {},
    status: 'idle'
}

export const houseSlice = createSlice({
    name: 'house',
    initialState,
    extraReducers: (builder) => {
        builder
            // add house
            .addCase(fetchAddHouseAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAddHouseAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchAddHouseAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // update house
            .addCase(fetchUpdateHouseAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateHouseAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateHouseAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // get house list
            .addCase(fetchGetHouseAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetHouseAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetHouseAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
            })
    },
    reducers: {
        clearHouse: (state => {
            state.entity = {};
        }),
    }
})

export const { clearHouse } = houseSlice.actions;
export const getHouse = (state) => state.house.entity;

export default houseSlice.reducer